<template>
  <footer class="footer">
    <div class="_container">
      <div class="footer__container">
        <div class="footer__socials">
          <router-link v-for="social in socials" :key="social.id" :to="social.link" class="footer__social">
            <img :src="require(`@/assets/img/socials/${social.img}`)" :alt="social.img" />
          </router-link>
        </div>
        <div class="footer__links">
          <router-link :to="`tel:${tel.replace(/[^+\d]/g, '')}`" class="footer__phone">{{ tel }}</router-link>
          <router-link to="/#" class="footer__conf">Политика обработки персональных данных</router-link>
          <router-link to="/#" class="footer__promo">Условия применения промокодов</router-link>
          <router-link to="/" class="footer__copy">©2023, Тюбетей</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterMain",
  data() {
    return {
      tel: `+7 (843) 249-41-41`,
      socials: [
        { id: 1, img: `Telegram.svg`, link: `` },
        { id: 2, img: `VK.svg`, link: `` },
        { id: 3, img: `YouTube.svg`, link: `` },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: $black;
  height: 91px;
  display: flex;
  align-items: center;
  ._container {
    width: 100%;
  }
  @media (max-width: $ms3) {
    height: 34px;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__socials {
    display: flex;
  }
  &__social {
    width: 47.92px;
    height: 47.92px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    &:not(:last-child) {
      margin-right: 10px;
    }
    &:hover,
    &:focus {
      filter: grayscale(1);
      transition: 0.2s ease 0.2s;
    }
    img {
      width: 50%;
    }
    @media (max-width: $ms3) {
      width: 16px;
      height: 16px;
    }
  }
  &__links {
    display: flex;
    justify-content: space-between;
    flex: 1 1 auto;
    margin-left: 3rem;
    @media (max-width: $ms3) {
      margin-left: 0;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
    @media (max-width: $ms5) {
      justify-content: space-between;
    }
    a {
      font-weight: 400;
      line-height: 21.6px;
      color: #fff;
      font-family: "Fregat";
      margin-left: 2rem;
      @media (max-width: $ms3) {
        line-height: 11.2px;
      }
      &:hover,
      &:focus {
        color: #ccc;
        transition: 0.2s ease 0.2s;
      }
    }
  }
  &__phone,
  &__copy {
    font-size: 18px;
    @media (max-width: $ms3) {
      font-size: 8px;
    }
  }
  &__phone {
    line-height: 21.6px;
  }
  &__conf,
  &__promo {
    font-size: 16px;
    line-height: 19.2px;
    @media (max-width: $ms3) {
      font-size: 8px;
    }
    @media (max-width: $ms5) {
      display: none;
    }
  }
  &__copy {
    line-height: 25.2px;
  }
}
</style>
