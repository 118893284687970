<template>
  <div class="discounts">
    <div class="discount">
      <div class="discount__body">
        <img :src="require(`@/assets/img/icons/${discount_icon}`)" :alt="discount_icon" />
        <h4 class="discount__title">{{ discount_title }}</h4>
        <div class="discount__text">{{ discount_text }}</div>
      </div>
      <div class="discount__img">
        <img :src="require(`@/assets/img/${discount}`)" :alt="discount" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DiscountBlock",
  data() {
    return {
      discount: `discount.png`,
      discount_icon: `discount.svg`,
      discount_title: `Скидка 20% на первый заказ`,
      discount_text: `Введите промокод «Tatar» оформлении заказа и получитите скидку на любимые блюда`,
    };
  },
};
</script>

<style lang="scss" scoped>
.discounts {
  display: flex;
  margin-top: 2rem;
  @media (max-width: $ms3) {
    margin-top: 1rem;
  }
  .discount {
    width: 310px;
    background-color: $green;
    border-radius: 20px;
    overflow: hidden;
    @media (max-width: $ms3) {
      width: 49%;
      border-radius: 10px;
    }
    &__body {
      padding: 1rem 1rem 1.5rem;
      @media (max-width: $ms4) {
        padding: 10px 10px 1.5rem;
      }
    }
    &__title {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      color: #fff;
      padding-right: 2rem;
      @media (max-width: $ms4) {
        font-size: 16px;
      }
      @media (max-width: $ms5) {
        font-size: 12px;
        line-height: 16px;
      }
    }
    &__text {
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      color: #fff;
      @media (max-width: $ms4) {
        font-size: 14px;
      }
      @media (max-width: $ms5) {
        font-size: 10px;
        line-height: 12px;
      }
    }
    &__img {
      height: 250px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
