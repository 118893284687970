<template>
  <div class="main">
    <section class="banner">
      <div class="_container">
        <div class="banner__body">
          <nav-stories />
          <h1 class="banner__title"><span></span> {{ title }}</h1>
          <h2 class="banner__subtitle">{{ subtitle }}</h2>
          <div class="banner__slider">
            <swiper
              class="swiper"
              :slides-per-view="3"
              :space-between="20"
              @swiper="onSwiperNext"
              @slideChange="onSlideChange"
            >
              <swiper-slide class="swiper-slide" v-for="slide in slides" :key="slide.id">
                <div class="swiper-img">
                  <img
                    :src="require(`@/assets/img/slider/${slide.img}`)"
                    :alt="slide.title"
                  />
                </div>
                <h4 class="swiper-title">{{ slide.title }}</h4>
              </swiper-slide>
            </swiper>
            <button class="banner__btn-arrow" @click="swiper.slideNext()">
              <img :src="require(`@/assets/img/icons/${arrow}`)" :alt="arrow" />
            </button>
          </div>
        </div>
      </div>
      <div class="banner__img">
        <img :src="require(`@/assets/img/${img}`)" :alt="img" class="desktop" />
        <img :src="require(`@/assets/img/${mobile}`)" :alt="mobile" class="mobile" />
      </div>
    </section>
    <section class="catalog">
      <div class="_container">
        <h2 class="catalog__title title">{{ title_catalog }}</h2>
        <div class="catalog__images">
          <router-link
            class="catalog__image"
            v-for="category in categories"
            :key="category.id"
            :to="category.link"
            :class="category.id > 5 && category.id < 12 ? `w-6` : `w-5`"
          >
            <img
              :src="require(`@/assets/img/catalog/${category.img}`)"
              :alt="category.title"
            />
          </router-link>
        </div>
      </div>
    </section>
    <section class="popular">
      <div class="_container">
        <div class="title">{{ popular_title }}</div>
        <filter-sorting />
        <dishes-blocks :dishes="dishes" />
        <discount-block />
      </div>
    </section>
    <our-mobile-app />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import NavStories from "@/components/NavStories.vue";
import OurMobileApp from "@/components/OurMobileApp.vue";
import FilterSorting from "@/components/FilterSorting.vue";
import DishesBlocks from "@/components/DishesBlocks.vue";
import DiscountBlock from "@/components/DiscountBlock.vue";
import { ref } from "vue";

export default {
  name: "MainView",
  components: {
    Swiper,
    SwiperSlide,
    NavStories,
    OurMobileApp,
    FilterSorting,
    DishesBlocks,
    DiscountBlock,
  },
  created() {
    document.title = "Тюбетей | Ресторан татарской кухни с доставкой на дом";
  },
  setup() {
    const swiper = ref();
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      swiper,
      onSwiperNext: (instance) => (swiper.value = instance),
    };
  },
  data() {
    return {
      title: `Современная татарская кухня`,
      subtitle: `Новинки сентября`,
      img: `main.png`,
      mobile: `mobile.png`,
      arrow: `arrow-r.svg`,
      slides: [
        { id: 1, img: `1.png`, title: `Кыстыбургер фирменный XL` },
        { id: 2, img: `2.png`, title: `Кыстыбургер с клубникой` },
        { id: 3, img: `3.png`, title: `Кыстыбургер с соусом ранчо` },
        { id: 4, img: `1.png`, title: `Кыстыбургер фирменный XL` },
        { id: 5, img: `2.png`, title: `Кыстыбургер с клубникой` },
        { id: 6, img: `3.png`, title: `Кыстыбургер с соусом ранчо` },
      ],
      title_catalog: `Каталог`,
      categories: [
        { id: 1, img: `1.png`, title: `новинки`, link: `#` },
        { id: 2, img: `2.png`, title: `горячее`, link: `#` },
        { id: 3, img: `3.png`, title: `кыстыбургеры`, link: `#` },
        { id: 4, img: `4.png`, title: `эчпочмакфреш`, link: `#` },
        { id: 5, img: `5.png`, title: `салаты`, link: `#` },
        { id: 6, img: `6.png`, title: `супы`, link: `#` },
        { id: 7, img: `7.png`, title: `выпечка`, link: `#` },
        { id: 8, img: `8.png`, title: `десерты`, link: `#` },
        { id: 9, img: `9.png`, title: `детское меню`, link: `#` },
        { id: 10, img: `10.png`, title: `напитки`, link: `#` },
        { id: 11, img: `11.png`, title: `соусы`, link: `#` },
        { id: 12, img: `12.png`, title: `полуфабрикаты`, link: `#` },
        { id: 13, img: `13.png`, title: `к чаю`, link: `#` },
        { id: 14, img: `14.png`, title: `пироги`, link: `#` },
        { id: 15, img: `15.png`, title: `сувениры`, link: `#` },
        { id: 16, img: `16.png`, title: `бакалея`, link: `#` },
      ],
      popular_title: `Популярное`,
      dishes: [
        {
          id: 1,
          img: `1.png`,
          title: `Кыстыбургер фирменный XL`,
          text: `Кыстыбургер с говядиной и овощами в фирменном соусе...`,
          sum: 509,
        },
        {
          id: 2,
          img: `2.png`,
          title: `Кыстыбургер с клубникой`,
          text: `Самый летний кыстыбургер с запеченной курицей, щавелем...`,
          sum: 259,
        },
        {
          id: 3,
          img: `3.png`,
          title: `Кыстыбургер с соусом ранчо`,
          text: `Кыстыбургер с варёно-копчёной кониной, фермерским соусом...`,
          sum: 259,
        },
        {
          id: 4,
          img: `4.png`,
          title: `Эчпочмаки фри с кортом`,
          text: `Эчпочмаки фри с кортом и варёной сгущенкой, как в детстве`,
          sum: 189,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.main {
  .stories {
    display: none;
    @media (max-width: $ms3) {
      display: flex;
    }
  }
  .banner {
    overflow: hidden;
    background-color: #d9d9d9;
    position: relative;
    height: auto;
    display: flex;
    align-items: center;
    padding: 5.65rem 0;
    ._container {
      margin: 0;
    }
    @media (max-width: $ms3) {
      padding: 8.21rem 0 1.5rem;
      .swiper-slide {
        width: 30% !important;
        margin-right: 10px !important;
      }
    }
    &::before {
      content: "";
      background-color: rgba(49, 119, 99, 0.9);
      width: 64.7%;
      height: 871px;
      border-radius: 0 50% 50% 0;
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      align-items: center;
      @media (max-width: $ms3) {
        top: 7rem;
        left: -5rem;
        bottom: unset;
        width: 93.34%;
        height: 99.37%;
        border-radius: 50%;
      }
    }
    &__img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__body {
      width: 100%;
      max-width: 750px;
      position: relative;
      z-index: 1;
      @media (max-width: $ms3) {
        width: 65%;
        max-width: unset;
      }
    }
    &__title {
      font-size: 30px;
      font-weight: 400;
      line-height: 10px;
      color: #fff;
      display: flex;
      align-items: center;
      @media (max-width: $ms3) {
        font-size: 14px;
      }
      span {
        width: 36px;
        height: 3px;
        background-color: #fff;
        border-radius: 3px;
        display: block;
        margin-right: 15px;
        @media (max-width: $ms3) {
          display: none;
        }
      }
    }
    &__subtitle {
      font-size: 70px;
      font-weight: 700;
      line-height: 10px;
      color: #fff;
      @media (max-width: $ms3) {
        font-size: 24px;
      }
    }
    &__slider {
      display: flex;
      align-items: center;
    }
    &__btn-arrow {
      background: none;
      border: none;
      cursor: pointer;
      position: relative;
      top: -1.5rem;
      z-index: 1;
      @media (max-width: $ms3) {
        top: -1rem;
      }
      &:hover,
      &:focus {
        img {
          filter: opacity(0.5);
          transition: 0.2s ease 0.2s;
        }
      }
    }
    .swiper-img {
      width: 200px;
      height: 150px;
      border-radius: 20px;
      overflow: hidden;
      @media (max-width: $ms3) {
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .swiper-title {
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      color: #fff;
      @media (max-width: $ms3) {
        font-size: 8px;
        line-height: 10px;
      }
    }
  }
  .catalog {
    margin-top: 3.5rem;
    @media (max-width: $ms4) {
      margin-top: 2rem;
    }
    &__images {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__image {
      margin-top: 1rem;
      height: 160px;
      border-radius: 20px;
      @media (max-width: $ms4) {
        height: 80px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      &:hover,
      &:focus {
        box-shadow: 0 0 5px 0px $orange;
        transition: 0.2s ease 0.2s;
      }
    }
    .w-5 {
      width: 18.85%;
      @media (max-width: $ms3) {
        width: 30%;
      }
    }
    .w-6 {
      width: 15.445%;
      @media (max-width: $ms3) {
        width: 30%;
      }
    }
  }
  .popular {
    margin-top: 5rem;
    padding-bottom: clamp(30px, 5vw, 80px);
    .filter {
      margin-top: 3rem;
    }
  }
}
</style>
