<template>
  <header-main />
  <main>
    <router-view />
  </main>
  <footer-main />
</template>

<script>
import HeaderMain from "./components/HeaderMain.vue";
import FooterMain from "./components/FooterMain.vue";
export default {
  name: "App",
  components: { HeaderMain, FooterMain },
};
</script>

<style lang="css" scoped>
main {
  overflow: hidden;
}
</style>
