<template>
  <div class="mobile-popup">
    <div class="mobile-popup__header">
      <div class="_container">
        <div class="mobile-popup__body">
          <div class="mobile-popup__arrow" @click="$emit('showMobilePopup')">
            <img :src="require(`@/assets/img/icons/${arrow}`)" :alt="arrow" />
          </div>
          <router-link to="/" class="mobile-popup__auth" @click="$emit('showModalLogin')">
            <span>{{ auth }}</span>
            <img :src="require(`@/assets/img/icons/${user}`)" :alt="user" />
          </router-link>
        </div>
      </div>
    </div>
    <div class="mobile-popup__pages">
      <div class="_container">
        <nav-pages @click="$emit('showMobilePopup')" />
        <div class="mobile-popup__socials">
          <router-link v-for="social in socials" :key="social.id" :to="social.link">
            <img :src="require(`@/assets/img/socials/mobile/${social.img}`)" :alt="social.img" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavPages from "./NavPages.vue";
export default {
  components: { NavPages },
  name: "MobilePopUp",
  data() {
    return {
      arrow: `arrow.svg`,
      user: `user-mobile.svg`,
      auth: `Войти`,
      socials: [
        { id: 1, img: `phone.svg`, link: `tel:+78432494141` },
        { id: 2, img: `telegram.svg`, link: `` },
        { id: 3, img: `email.svg`, link: `mailto:` },
        { id: 4, img: `vk.svg`, link: `` },
        { id: 4, img: `location.svg`, link: `` },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.mobile-popup {
  position: fixed;
  background-color: #fff;
  z-index: 997;
  width: 100%;
  height: 100%;
  top: -100%;
  opacity: 0;
  animation: showPopup 0.2s forwards 0.2s;
  @keyframes showPopup {
    0% {
      opacity: 0;
      top: -100%;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }
  &__header {
    background-color: #d66c56;
    height: 48px;
  }
  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__body,
  ._container {
    width: auto;
    height: 100%;
  }
  &__pages {
    padding: 1rem 0;
  }
  &__auth {
    font-family: "Inter";
    font-size: 12px;
    line-height: 14.52px;
    color: #fff;
    display: flex;
    align-items: center;
    span {
      padding: 0 10px;
    }
  }
  &__socials {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    a {
      width: 24px;
      height: 24px;
      background: #f7f7f7;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
    }
  }
}
</style>
