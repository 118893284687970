<template>
    <div class="modal-wrapper" :class="{ active : modalShow }">
        <div class="modal-login">
            <button class="modal-wrapper-close" @click="hideModal">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M1 1L19 19" stroke="#373737" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M1 19L19 1" stroke="#373737" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            <div v-if="modalPhone" class="modal-login-body modal-login-body_phone">
                <div class="modal-login__title">Здравствуйте, авторизируйтесь</div>
                <div class="modal-login__text">Так вы сможете получать баллы и быстрее оформлять заказы</div>
                <div class="modal-login__input">
                    <input type="tel" placeholder="+7 000 000 00 00">
                    <button @click="modalCode = true, modalPhone = false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="23" viewBox="0 0 13 23" fill="none">
                            <path d="M1 1.94824L11 11.8923L1 21.8364" stroke="#373737" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                    </button>
                </div>
                <div class="modal-login__privacy">Указывая номер телефона вы принимаете <a href="#">Политику обработки персональных данных</a></div>
            </div>

            <div v-if="modalCode" class="modal-login-body modal-login-body_phone">
                <div class="modal-login__title">Введите код</div>
                <div class="modal-login__text">Мы отправили его на номер +7 000 000 00 00</div>
                <div class="modal-login__input modal-login__input_code">
                    <input type="tel" id="code" v-model="code" placeholder="0000">
                </div>
                <div class="modal-login-buttons">
                    <button>Повторить SMS</button>
                    <button @click="modalCode = false, modalPhone = true">Изменить данные</button>
                </div>
            </div>

            <div v-if="modalName" class="modal-login-body modal-login-body_phone">
                <div class="modal-login__title">Введите ваше имя</div>
                <div class="modal-login__input modal-login__input_code">
                    <input type="tel" id="code" placeholder="Анна">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "BreadCrumbs",

    props: {
        modalShow: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            modalPhone: true,
            modalCode: false,
            modalName: false,
            code: '',
        }
    },

    methods: {
        hideModal() {
            this.$emit('close')
            this.modalPhone = true,
            this.modalCode = false,
            this.modalName = false
        },
    },

    watch: {
        code(newValue) {
            if (newValue.length === 4) {
                this.modalCode = false
                this.modalName = true
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .modal-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        display: none;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: $black;
        background: rgba(28,28,28,.4);
        z-index: 998;
        &.active {
            display: flex;
        }
        &-close {
            position: absolute;
            top: 22px;
            right: 22px;
            background: none;
            border: none;
            cursor: pointer;
            z-index: 999;
            @media (max-width: $ms4) {
                top: 12px;
                right: 12px;
            }
        }
    }
    .modal-login {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 600px;
        background: #FEFEFE;
        transform: translate(-50%,-50%);
        @media (max-width: $ms4) {
            width: 90%;
        }
        &-body {
            position: relative;
            padding: 64px 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            box-sizing: border-box;
            @media (max-width: $ms4) {
                padding: 48px 34px;
            }
        }
        &__title {
            margin-bottom: 30px;
            font-size: 30px;
            line-height: 40px;
            @media (max-width: $ms4) {
                margin-bottom: 20px;
                font-size: 24px;
                line-height: 30px;
            }
        }
        &__text {
            margin-bottom: 30px;
            font-size: 18px;
            line-height: 22px;
            @media (max-width: $ms4) {
                margin-bottom: 20px;
                font-size: 14px;
                line-height: 14px;
            }
        }
        &__input {
            position: relative;
            margin-bottom: 10px;
            width: 100%;
            & input {
                padding-left: 16px;
                width: 100%;
                font-family: 'Inter';
                font-size: 18px;
                line-height: 35px;
                border: 1px solid #D9D9D9;
                border-radius: 10px;
                box-sizing: border-box;
                @media (max-width: $ms4) {
                    font-size: 14px;
                }
                &:focus-visible {
                    outline: none;
                }
            }
            & button {
                padding: 0 14px;
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                background: #EFEFEF;
                border: none;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                cursor: pointer;
            }
            &_code {
                & input {
                    padding: 0;
                    width: 230px;
                    font-size: 22px;
                    text-align: center;
                    font-weight: 600;
                    border: none;
                    border-bottom: 1px solid $black;
                    border-radius: 0;
                    @media (max-width: $ms4) {
                        width: 140px;
                        font-size: 18px;
                    }
                    &:focus-visible {
                        outline: none;
                        border-bottom: 1px solid #000;
                    }
                }
            }
        }
        &__privacy {
            font-size: 12px;
            color: #7C7F7E;
            & a {
                text-decoration: underline;
                color: #7C7F7E;
            }
        }
        &-buttons {
            margin-top: 42px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            & button {
                font-size: 20px;
                color: $orange;
                background: none;
                border: none;
                cursor: pointer;
                @media (max-width: $ms4) {
                    font-size: 14px;
                }
                &:hover {
                    color: #317763;
                }
            }
        }
    }
</style>