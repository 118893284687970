<template>
  <div class="our-mobile-app">
    <div class="_container">
      <div class="our-mobile-app__container">
        <div class="our-mobile-app__cols">
          <div class="our-mobile-app__col">
            <h4 class="our-mobile-app__head">{{ head1 }}</h4>
            <ul class="our-mobile-app__pages">
              <li class="our-mobile-app__item" v-for="item1 in items1" :key="item1.id">
                <router-link :to="item1.link">{{ item1.name }}</router-link>
              </li>
            </ul>
          </div>
          <div class="our-mobile-app__col">
            <h4 class="our-mobile-app__head">{{ head2 }}</h4>
            <ul class="our-mobile-app__pages">
              <li class="our-mobile-app__item" v-for="item2 in items2" :key="item2.id">
                <router-link :to="item2.link">{{ item2.name }}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="our-mobile-app__img">
          <img :src="require(`@/assets/img/${img}`)" :alt="img" />
        </div>
        <div class="our-mobile-app__fill">
          <h3 class="our-mobile-app__title">{{ title }}</h3>
          <p class="our-mobile-app__subtitle">
            {{ subtitle }} <span>{{ promocode }}</span>
          </p>
          <div class="our-mobile-app__apps">
            <router-link to="#" class="our-mobile-app__app">
              <img :src="require(`@/assets/img/${app1}`)" :alt="app1" />
            </router-link>
            <router-link to="#" class="our-mobile-app__app">
              <img :src="require(`@/assets/img/${app2}`)" :alt="app2" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurMobileApp",
  data() {
    return {
      title: `Заливай наше мобильное приложение`,
      subtitle: `Скидка 10% при заказе через приложение по промокоду `,
      promocode: `МИНУС10`,
      head1: `Клиентам`,
      head2: `Партнерам`,
      img: `phone.png`,
      app1: `app-store.png`,
      app2: `google-play.png`,
      items1: [
        { id: 1, link: `#`, name: `Доставка` },
        { id: 2, link: `#`, name: `Рестораны` },
        { id: 3, link: `#`, name: `Отзывы` },
        { id: 4, link: `#`, name: `О нас` },
        { id: 5, link: `#`, name: `Контакты` },
      ],
      items2: [
        { id: 1, link: `#`, name: `Инвестиции` },
        { id: 2, link: `#`, name: `Оптовикам` },
        { id: 3, link: `#`, name: `Туристическим группам` },
        { id: 4, link: `#`, name: `Поставщикам` },
        { id: 5, link: `#`, name: `Вакансии` },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.our-mobile-app {
  border-top: 1px solid #cdcdcd;
  padding-top: 3rem;
  @media (max-width: $ms4) {
    padding-top: 0;
    background: #f7f7f9;
  }
  ._container {
    width: auto;
  }
  &__container {
    display: flex;
    justify-content: space-between;
    margin-bottom: -4.5rem;
    @media (max-width: $ms1) {
      margin-bottom: -5.5rem;
    }
    @media (max-width: $ms2) {
      flex-wrap: wrap;
      justify-content: center;
    }
    @media (max-width: $ms4) {
      margin-bottom: -2.65rem;
    }
  }
  &__cols {
    display: flex;
  }
  &__col {
    margin-right: 5rem;
    @media (max-width: $ms2) {
      margin-right: 3rem;
    }
  }
  &__head {
    font-size: 30px;
    font-weight: 400;
    line-height: 30.9px;
    @media (max-width: $ms3) {
      font-size: 18px;
    }
    @media (max-width: $ms4) {
      font-size: 14px;
      margin-bottom: 2rem;
    }
    @media (max-width: $ms5) {
      font-size: 10px;
      line-height: 10px;
      color: #000;
    }
  }
  &__pages {
    margin: -1.5rem 0;
  }
  &__item {
    margin-bottom: 1rem;
    @media (max-width: $ms5) {
      margin-bottom: 0;
    }
    a {
      font-size: 16px;
      font-weight: 700;
      line-height: 16.48px;
      @media (max-width: $ms3) {
        font-size: 12px;
      }
      @media (max-width: $ms5) {
        font-size: 10px;
      }
      &:hover,
      &:focus {
        color: #777;
        transition: 0.2s ease 0.2s;
      }
    }
  }
  &__img {
    width: 384px;
    height: 362px;
    @media (max-width: $ms2) {
      order: 1;
      margin: 2rem auto 0;
    }
    @media (max-width: $ms4) {
      width: 156px;
      height: 176px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__fill {
    max-width: 375px;
    @media (max-width: $ms3) {
      max-width: unset;
      margin-top: 2rem;
    }
  }
  &__title {
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    @media (max-width: $ms3) {
      font-size: 18px;
    }
    @media (max-width: $ms4) {
      font-size: 12px;
      font-weight: 700;
      line-height: 15.84px;
    }
  }
  &__subtitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    text-align: center;
    margin-top: -0.5rem;
    @media (max-width: $ms3) {
      margin-top: 0;
      font-size: 14px;
    }
    @media (max-width: $ms4) {
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
    }
    span {
      font-weight: 400;
      text-decoration: underline;
      text-transform: uppercase;
    }
  }
  &__apps {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
  }
  &__app {
    display: flex;
    width: 177px;
    height: 60px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    margin: 0 1rem;
    @media (max-width: $ms4) {
      width: 88px;
      height: 32px;
    }
    &:hover,
    &:focus {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
      transition: 0.2s ease 0.2s;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
