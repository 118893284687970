<template>
  <div class="dishes">
    <div class="dish" v-for="dish in dishes" :key="dish.id">
      <div class="dish__img">
        <img :src="require(`@/assets/img/dishes/${dish.img}`)" :alt="dish.img" />
        <div class="dish__favourites" @click="selectFavourite">
          <img v-if="isFavourite" :src="require(`@/assets/img/icons/favourites_active.svg`)" alt="favorites" />
          <img v-else :src="require(`@/assets/img/icons/favourites.svg`)" alt="favorites" />
        </div>
        <router-link to="#" class="dish__watch-video">{{ text_watch_video }}</router-link>
      </div>
      <div class="dish__body">
        <h4 class="dish__title">{{ dish.title }}</h4>
        <p class="dish__text">{{ dish.text }}</p>
        <div class="dish__adds">
          <div class="dish__add" v-for="icon in icons" :key="icon.id">
            <div class="dish__icon">
              <img :src="require(`@/assets/img/adds/${icon.img}`)" :alt="icon.name" />
            </div>
            <div class="dish__name-add">{{ icon.name }}</div>
          </div>
        </div>
        <button class="dish__btn-add btn">{{ text_add_for }} {{ dish.sum }} {{ rub }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DishesBlocks",
  data() {
    return {
      isFavourite: false,
      text_watch_video: `Смотреть видео`,
      text_add_for: `Добавить за`,
      rub: `₽`,
      icons: [
        { id: 1, img: `free-icon-chilli.png`, name: `Острое` },
        { id: 2, img: `free-icon-meat.png`, name: `Конина` },
        { id: 3, img: `free-icon-garlic.png`, name: `Чеснок` },
        { id: 4, img: `free-icon-sparkles.png`, name: `Популярное` },
      ],
    };
  },
  methods: {
    selectFavourite() {
      this.isFavourite = !this.isFavourite;
    },
  },
  props: ["dishes"],
};
</script>

<style lang="scss" scoped>
.dishes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .dish {
    width: 310px;
    background-color: #fefefe;
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    overflow: hidden;
    margin-top: 2rem;
    @media (max-width: $ms2) {
      width: 49%;
      border-radius: 10px;
      margin-top: 2rem;
    }
    @media (max-width: $ms3) {
      margin-top: 1rem;
    }
    &__img {
      height: 210px;
      overflow: hidden;
      position: relative;
      @media (max-width: $ms3) {
        height: 120px;
      }
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        box-shadow: 0 0 80px 50px #000;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__favourites {
      position: absolute;
      top: 1rem;
      right: 1rem;
      img {
        width: 29.84px;
        height: 25px;
        @media (max-width: $ms3) {
          width: 15px;
          height: 13px;
        }
      }
    }
    &__watch-video {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      font-size: 14px;
      font-weight: 400;
      line-height: 14.42px;
      color: #fff;
      @media (max-width: $ms4) {
        font-size: 8px;
        line-height: 8.24px;
      }
      &:hover,
      &:focus {
        text-decoration: underline;
        transition: 0.2s ease 0.2s;
      }
    }
    &__body {
      padding: 0 20px 30px;
      @media (max-width: $ms4) {
        padding: 0 10px 15px;
      }
      @media (max-width: $ms5) {
        padding: 0 5px 10px;
      }
    }
    &__title {
      font-size: 20px;
      font-weight: 700;
      line-height: 20.6px;
      @media (max-width: $ms4) {
        font-size: 12px;
        line-height: 16px;
      }
    }
    &__text {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin-top: -3.2px;
      @media (max-width: $ms4) {
        font-size: 10px;
        line-height: 12px;
      }
    }
    &__adds {
      display: flex;
      @media (max-width: $ms3) {
        flex-wrap: wrap;
      }
    }
    &__add {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 5px;
      @media (max-width: $ms3) {
        padding: 5px;
      }
      @media (max-width: $ms5) {
        padding: 2.5px;
      }
    }
    &__icon {
      width: 35.01px;
      height: 35.01px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: $ms4) {
        width: 24px;
        height: 24px;
      }
    }
    &__name-add {
      font-size: 14px;
      font-weight: 400;
      line-height: 14.42px;
      margin-top: 10px;
      @media (max-width: $ms4) {
        font-size: 8px;
        line-height: 8.24px;
      }
    }
    &__btn-add {
      width: 100%;
      height: 40px;
      margin-top: 1.5rem;
      @media (max-width: $ms4) {
        height: 24px;
      }
    }
  }
}
</style>
