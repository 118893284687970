<template>
  <div class="nav">
    <router-link v-for="page in pages" :key="page.id" :to="page.link">{{ page.name }}</router-link>
  </div>
</template>

<script>
export default {
  name: "NavPages",
  data() {
    return {
      pages: [
        { id: 1, link: `#`, name: `Доставка` },
        { id: 2, link: `#`, name: `Рестораны` },
        { id: 3, link: `#`, name: `Отзывы` },
        { id: 4, link: `#`, name: `Контакты` },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.nav {
  @media (max-width: $ms1) {
    width: 50%;
    text-align: right;
    padding: 1rem 0;
  }
  @media (max-width: $ms3) {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  a {
    font-size: 16px;
    font-weight: 400;
    line-height: 16.48px;
    text-align: center;
    margin: 0 10px;
    @media (max-width: $ms3) {
      font-size: 12px;
      line-height: 12.36px;
      text-align: left;
      margin: 1rem 0px 0px 0px;
    }
    &:hover,
    &:focus {
      color: #777;
      transition: 0.2s ease 0.2s;
    }
  }
}
</style>
