<template>
  <div class="filter">
    <ul class="items">
      <li class="item" v-for="item in items" :key="item.id" :class="item.select ? `_select` : false">
        <span>{{ item.name }}</span>
        <img :src="require(`@/assets/img/icons/${exit}`)" :alt="exit" v-if="item.select" />
      </li>
    </ul>
    <div class="sorting" @click="isPopupSort = !isPopupSort">
      <div class="sort">
        <img :src="require(`@/assets/img/icons/${sort}`)" :alt="sort" />
      </div>
      <span>{{ text_sort }}</span>
    </div>
    <div class="popup-sorting" v-if="isPopupSort">
      <p class="popup-sorting__question">{{ sorting_question }}</p>
      <div class="popup-sorting__list">
        <div class="popup-sorting__var" v-for="sorting in sortings" :key="sorting.id">
          <input type="radio" name="sorting" :checked="sorting.id === 1 ? true : false" :id="sorting.name" />
          <label :for="sorting.name">{{ sorting.label }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterSorting",
  data() {
    return {
      isPopupSort: false,
      items: [
        { id: 1, name: `Острое`, select: false },
        { id: 2, name: `Хит`, select: false },
        { id: 3, name: `Конина`, select: false },
        { id: 4, name: `Специи`, select: true },
      ],
      exit: `exit.svg`,
      sort: `sort.svg`,
      text_sort: `Сортировка`,
      sortings: [
        { id: 1, label: `Доверюсь вам`, name: `sorting1` },
        { id: 2, label: `Недорогие`, name: `sorting2` },
        { id: 3, label: `Дорогие`, name: `sorting3` },
        { id: 4, label: `С высоким рейтингом`, name: `sorting4` },
        { id: 5, label: `Низкая колорийность`, name: `sorting5` },
      ],
      sorting_question: `Какие показать сначала?`,
    };
  },
};
</script>

<style lang="scss" scoped>
.filter {
  display: flex;
  justify-content: space-between;
  position: relative;
  @media (max-width: $ms4) {
    flex-direction: column-reverse;
    align-items: flex-end;
    margin-top: -1rem;
  }
  .items {
    display: flex;
    @media (max-width: $ms4) {
      width: 100%;
      margin-top: 1rem;
    }
    ._select {
      background-color: #e0d19f;
    }
  }
  .item {
    background-color: #eaeaea;
    border-radius: 30px;
    width: fit-content;
    height: 41px;
    font-size: 20px;
    font-weight: 400;
    line-height: 20.6px;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    cursor: pointer;
    margin: 0 8px;
    img {
      margin-left: 5px;
      @media (max-width: $ms3) {
        width: 6px;
        height: 6px;
      }
    }
    &:hover,
    &:focus {
      background-color: #ccc;
      transition: 0.2s ease 0.2s;
    }
    @media (max-width: $ms3) {
      border-radius: 20px;
      height: 25px;
      font-size: 10px;
      font-weight: 700;
      line-height: 10.3px;
      margin: 0 5px;
    }
  }
  .sorting {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover,
    &:focus {
      opacity: 0.5;
      transition: 0.2s ease 0.2s;
    }
  }
  .sort {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    padding: 5px;
    @media (max-width: $ms3) {
      width: 14.5px;
      height: 14.5px;
      padding: 2.5px;
    }
    ~ span {
      font-size: clamp(12px, 5vw, 20px);
      font-weight: 700;
      line-height: 20.6px;
      text-align: center;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .popup-sorting {
    width: 330px;
    height: 360px;
    background-color: #fff;
    border-radius: 20px;
    padding: 0.5rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
    opacity: 0;
    box-shadow: 0 0 5px 0px #ccc;
    animation: showSort 0.2s forwards 0.2s;
    @keyframes showSort {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @media (max-width: $ms4) {
      width: 210px;
      height: 270px;
      padding: 2rem 1rem;
    }
    &__question {
      font-size: 20px;
      line-height: 20.6px;
      @media (max-width: $ms4) {
        font-size: 12px;
        line-height: 12.36px;
      }
    }
    &__list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 1.5rem 0 1rem;
      @media (max-width: $ms4) {
        padding: 0.5rem 0 0;
      }
    }
    &__var {
      display: flex;
      align-items: center;
      input[type="radio"] {
        position: absolute;
        z-index: -1;
        opacity: 0;
      }
      input[type="radio"] + label {
        display: flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
        font-size: 18px;
        line-height: 18.54px;
        @media (max-width: $ms4) {
          font-size: 12px;
          line-height: 12.36px;
        }
        &::before {
          content: "";
          width: 20px;
          height: 20px;
          box-sizing: border-box;
          border-radius: 50%;
          cursor: pointer;
          margin-right: 5px;
          background: #f5f5f5;
          box-shadow: inset 0 0 0px 2px #ededed;
          @media (max-width: $ms4) {
            width: 16px;
            height: 16px;
          }
        }
      }
      input[type="radio"]:checked + label::before {
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: inset 0 0 0px 5px $orange;
        @media (max-width: $ms4) {
          box-shadow: inset 0 0 0px 2.5px $orange;
        }
      }
    }
  }
}
</style>
