<template>
  <mobile-pop-up v-if="isMobilePopup" @showMobilePopup="showMobilePopup" @showModalLogin="modalLoginShow"/>
  <modal-login :modalShow="isModalLogin" @close="modalLoginHide"/>
  <header class="header">
    <div class="_container">
      <div class="header__container">
        <button class="btn-burger" @click="showMobilePopup"><span></span><span></span><span></span></button>
        <router-link to="/" class="header__logo">
          <img :src="require(`@/assets/img/${logo}`)" :alt="logo" />
        </router-link>
        <nav-pages />
        <form class="header__search">
          <img :src="require(`@/assets/img/icons/${search}.svg`)" :alt="search" />
          <input :type="search" :name="search" :id="search" :placeholder="placeholder" />
        </form>
        <nav-stories />
        <div class="header__auth">
          <router-link to="/" @click="isModalLogin = true"
            ><img :src="require(`@/assets/img/icons/${user}`)" :alt="user" /><span>{{ auth }}</span></router-link
          >
        </div>
        <div class="header__cart">
          <router-link to="/cart">
            <span class="header__counts">{{ counts }}</span>
            <img :src="require(`@/assets/img/icons/${cart}`)" :alt="cart" />
          </router-link>
          <div class="header__sum">{{ sum }} {{ rub }}</div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import NavPages from "./NavPages.vue";
import NavStories from "./NavStories.vue";
import MobilePopUp from "./MobilePopUp.vue";
import ModalLogin from "@/components/ModalLogin.vue";
export default {
  components: { NavPages, NavStories, MobilePopUp, ModalLogin },
  name: "HeaderMain",
  data() {
    return {
      logo: `logo.svg`,
      search: `search`,
      placeholder: `Поиск`,
      user: `user.svg`,
      auth: `Войти`,
      cart: `cart.svg`,
      sum: 0,
      rub: `₽`,
      counts: 0,
      isMobilePopup: false,
      isModalLogin: false,
    };
  },
  methods: {
    showMobilePopup() {
      this.isMobilePopup = !this.isMobilePopup;
    },
    modalLoginShow() {
      this.isModalLogin = true
    },
    modalLoginHide() {
      this.isModalLogin = false
    }
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-color: #f8f8f8;
  height: 80px;
  display: flex;
  align-items: center;
  ._container {
    width: 100%;
  }
  @media (max-width: $ms1) {
    height: auto;
    padding: 1rem 0;
  }
  @media (max-width: $ms3) {
    padding: 0;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__logo {
    width: 129px;
    height: 28.2px;
    display: block;
    @media (max-width: $ms1) {
      width: 50%;
      padding: 1rem 0;
    }
    @media (max-width: $ms3) {
      width: 77.75px;
      height: 17px;
      text-align: center;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      @media (max-width: $ms1) {
        width: auto;
      }
    }
  }
  &__search {
    background: #efefef;
    width: 250px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    @media (max-width: $ms1) {
      margin: 1rem;
      order: 1;
      flex: 1 1 auto;
    }
    @media (max-width: $ms3) {
      display: none;
    }
    input {
      font-size: 16px;
      font-weight: 400;
      line-height: 16.48px;
      background: inherit;
      border: none;
      outline: none;
      padding: 0 10px;
      &::placeholder {
        color: $black;
      }
    }
    img {
      width: 20.5px;
      height: 20.5px;
      margin-left: 10px;
    }
  }
  &__auth {
    @media (max-width: $ms1) {
      margin: 1rem;
      order: 1;
    }
    @media (max-width: $ms3) {
      display: none;
    }
    a {
      font-size: 20px;
      font-weight: 400;
      line-height: 20.6px;
      text-align: center;
      color: $black;
      display: flex;
      align-items: center;
      &:hover,
      &:focus {
        opacity: 0.5;
        transition: 0.2s ease 0.2s;
      }
      img {
        width: 29px;
        height: 28px;
      }
      span {
        margin: 5px 0px 0px 5px;
      }
    }
  }
  &__cart {
    display: flex;
    align-items: center;
    @media (max-width: $ms1) {
      order: 1;
    }
    a {
      position: relative;
      padding: 0 10px;
      &:hover,
      &:focus {
        opacity: 0.5;
        transition: 0.2s ease 0.2s;
      }
      img {
        width: 26px;
        height: 20px;
        @media (max-width: $ms3) {
          width: 21.9px;
          height: 16.85px;
        }
      }
    }
  }
  &__sum {
    font-size: 20px;
    font-weight: 400;
    line-height: 20.6px;
    text-align: center;
    color: $black;
    border-left: 1.5px solid $black;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    @media (max-width: $ms3) {
      display: none;
    }
  }
  &__counts {
    width: 14px;
    height: 10px;
    border-radius: 50px;
    background: $orange;
    display: none;
    justify-content: center;
    align-items: flex-start;
    font-size: 8px;
    font-weight: 600;
    line-height: 10.91px;
    text-align: center;
    color: #fff;
    font-family: "Nunito Sans";
    position: absolute;
    top: 0;
    left: 7px;
    @media (max-width: $ms3) {
      display: flex;
    }
  }
  @media (max-width: $ms3) {
    .nav,
    .stories {
      display: none;
    }
  }
}
.btn-burger {
  width: auto;
  height: 17px;
  background: none;
  border: none;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  @media (max-width: $ms3) {
    display: flex;
  }
  span {
    height: 1px;
    background: $black;
    display: flex;
    &:first-child {
      width: 23px;
    }
    &:nth-child(2) {
      width: 11px;
    }
    &:last-child {
      width: 19px;
    }
  }
}
</style>
