<template>
  <div class="stories">
    <div class="story" v-for="story in stories" :key="story.id">
      <img :src="require(`@/assets/img/stories/${story.img}`)" :alt="story.img" />
    </div>
  </div>
</template>

<script>
export default {
  name: "NavStories",
  data() {
    return {
      stories: [
        { id: 1, img: `1.png` },
        { id: 2, img: `2.png` },
        { id: 3, img: `3.png` },
        { id: 4, img: `4.png` },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.stories {
  display: flex;
  margin: 0 7px;
}
.story {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 -7px;
  @media (max-width: $ms3) {
    width: 33.88px;
    height: 33.88px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
